import { useEffect, useState } from "react";
import {createUseStyles} from 'react-jss'

const DEFAULT_DATA = {
  id: '1',
  name: 'Button Name',
  data: {
    darkMode: true,
    
    fontFamily: 'default', // default, serif or mono
    fontBold: false,
    label: '',
    link: '',
    fullWidth: false,
    fullHeight: false,
    borderRadius: '15px',

    backgroundColor: '#0066aa',
    hoverBackgroundColor: '#0066c0',
    activeBackgroundColor: '#006080',
    labelColor: '#fff',
    borderColor: '#ffffff33',
    borderWidth: '0',
  },
};

const useStyles = createUseStyles({
  button: (data) => ({
    backgroundColor: data.backgroundColor ?? DEFAULT_DATA.data.backgroundColor,
    borderStyle: 'solid',
    borderColor: data.borderColor ?? DEFAULT_DATA.data.borderColor,
    borderWidth: data.borderWidth ?? DEFAULT_DATA.data.borderWidth,
    padding: '.8rem 1.5rem',
    color: data.labelColor ?? DEFAULT_DATA.data.labelColor,
    width: data.fullWidth ? '100%' : 'auto',
    height: data.fullHeight ? '100%' : 'auto',
    borderRadius: data.borderRadius ?? DEFAULT_DATA.data.borderRadius,
    fontWeight: data.fontBold ? 'bold' : 'normal',
    cursor: data.link ? 'pointer' : 'auto',
    transition: '150ms linear background',

    '&:hover': {
      backgroundColor: data.hoverBackgroundColor ?? DEFAULT_DATA.data.hoverBackgroundColor,
    },

    '&:active': {
      backgroundColor: data.activeBackgroundColor ?? DEFAULT_DATA.data.activeBackgroundColor,
    },
  }),
});


function App() {
  const [, setName] = useState('');
  const [data, setData] = useState({});
  const [isPreview, setIsPreview] = useState(false);

  const classes = useStyles(data);

  useEffect(() => {
    const update = () => {
      const {name, data} = JSON.parse(document.getElementById('EMBED_DATA')?.textContent ?? null) ?? DEFAULT_DATA;
      setName(name);
      setData(data ?? {});
    }
    if (isPreview) {
      const interval = setInterval(() => update(), 100);
      return () => clearInterval(interval);
    } else {
      update();
    }
  }, [isPreview]);

  useEffect(() => setIsPreview(!!document.querySelector('script.preview')), []);

  return (
    <div className={`
      embed-root 
      ${data.darkMode ? 'dark' : ''}
      font-${data.fontFamily ??  'default'}
    `}>
      <button 
        onClick={() => data.link ? window.open(data.link, '_blank') : null}
        className={classes.button}
      >
        {data.label ?? DEFAULT_DATA.data.label}
      </button>
    </div>
  );
}

export default App;
